.u-legend__tiny {
  font-size: 10px;
  line-height: 1.2;
}

.u-legend__tiny th {
  padding: 0;
}
.u-legend__tiny td {
  padding: 0 0 0 5px;
}
